<template>
  <div class="form_main">
    <el-form :model="cryptoForm" ref="cryptoForm" :rules="cryptoRules">
      <ul class="clearfix">
        <li class="fl">
          <AccountNumber
            supportedCurrencies="all"
            @setAccountNumber="setAccountNumber"
            @setCurrency="setCurrency"
          ></AccountNumber>
        </li>
        <li class="fr">
          <numeric-input
            v-model="cryptoForm.amount"
            :currency="cryptoForm.currency"
            :precision="2"
            :label="$t('common.field.amt')"
            name="amount"
          ></numeric-input>
        </li>
      </ul>
      <ul class="clearfix">
        <li class="fl">
          <InputForm
            :label="$t('common.field.imptNotes')"
            name="applicationNotes"
            v-model="cryptoForm.notes"
          ></InputForm>
        </li>
      </ul>
      <div class="form_button">
        <el-button
          class="el-button purple_button"
          :loading="loading"
          :disabled="loading"
          @click="submit()"
          data-testid="submit"
        >
          {{ $t('common.button.submit') }}
        </el-button>
      </div>
    </el-form>
    <div v-html="bankHTML"></div>
  </div>
</template>

<script>
import cryptyoMixin from '@/mixins/page/deposit/crypto_cps';
import mixin from '@/mixins/page/deposit';
import { apiCryptouBtcCps_payment } from '@/resource';

export default {
  name: 'CryptoDepositBitcoinCPS',
  mixins: [cryptyoMixin, mixin],
  methods: {
    submitDeposit() {
      return apiCryptouBtcCps_payment(
        {
          mt4Account: this.cryptoForm.accountNumber,
          operateAmount: this.cryptoForm.amount,
          applicationNotes: this.cryptoForm.notes,
          paymentMethod: 'cryptousdt',
          baseCurrency: this.cryptoForm.currency,
          targetCurrency: 'USDT'
        },
        this.token
      );
    }
  }
};
</script>
