<template>
  <div id="">
    <div class="content_box">
      <div class="inner">
        <div class="box_card_top">
          <h4>{{ $t('deposit.crypto.header') }}</h4>
          <div class="info_box">
            <p>{{ $t(`deposit.crypto.${channel.i18n}.desc`, { erc: channel.name }) }}</p>
            <ul>
              <li>{{ $t(`deposit.crypto.${channel.i18n}.inst1`, { erc: channel.name }) }}</li>
              <li>{{ $t(`deposit.crypto.${channel.i18n}.inst2`, { erc: channel.name }) }}</li>
              <li>{{ $t(`deposit.crypto.${channel.i18n}.inst3`, { erc: channel.name }) }}</li>
              <li>{{ $t(`deposit.crypto.note`) }}</li>
            </ul>
          </div>
          <div class="channel_box">
            <p>{{ $t('deposit.crypto.channel') }}</p>
            <el-select :value="channel.name" @change="updateDepositChannel" data-testid="selectedChannel">
              <el-option
                v-for="(item, index) in channelList"
                :key="item.name"
                :label="item.name"
                :value="index"
                data-testid="depositChannel"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box_card_bottom">
          <component
            :is="channel.component"
            :channel="channel"
            :dialogVisible.sync="dialog.visible"
            @validateForm="submitForm"
            ref="child"
          ></component>
        </div>
        <vDialog :show.sync="dialog.visible">
          <template v-slot:body>
            <p v-html="$t('deposit.crypto.proceedQuestion', { method: channel.name })"></p>
          </template>
          <template v-slot:btn>
            <div class="form_button">
              <el-button class="purple_button" @click="onConfirm" data-testid="yes">
                {{ $t('common.field.yes') }}
              </el-button>
              <el-button class="white_button" @click="dialog.visible = false" data-testid="no">
                {{ $t('common.field.no') }}
              </el-button>
            </div>
          </template>
        </vDialog>
      </div>
    </div>
  </div>
</template>

<script>
import vDialog from '@/components/vDialog';
import Bitcoin_CPS from '@/components/deposit/Crypto/Bitcoin_CPS';
import USDT_CPS from '@/components/deposit/Crypto/USDT_CPS';
import TRC20_CPS from '@/components/deposit/Crypto/TRC20_CPS';

export default {
  name: 'Crypto',
  components: {
    vDialog,
    Bitcoin_CPS,
    USDT_CPS,
    TRC20_CPS
  },
  data() {
    return {
      channel: {
        name: '',
        i18n: '',
        component: null
      },
      dialog: { visible: false, method: '' },
      channelList: [
        { name: 'Bitcoin', i18n: 'bitcoin', component: Bitcoin_CPS },
        { name: 'USDT(ERC20)', i18n: 'erc', component: USDT_CPS },
        { name: 'USDT(TRC20)', i18n: 'erc', component: TRC20_CPS }
      ]
    };
  },
  mounted() {
    this.channel = this.channelList[0];
  },
  methods: {
    updateDepositChannel(index) {
      this.channel = this.channelList[index];
    },
    submitForm() {
      this.$refs.child.$refs['cryptoForm'].validate(valid => {
        if (valid) this.dialog.visible = true;
        else return false;
      });
    },
    onConfirm() {
      const vm = this;
      this.$refs.child.loading = true;
      this.$refs.child
        .submitDeposit()
        .then(result => {
          if (result.data.code == 0 && result.data.data.length > 0) {
            vm.$refs.child.successMethod(result.data.data);
          } else {
            vm.errorHandler();
          }
        })
        .catch(err => {
          this.errorHandler();
        });
    },
    errorHandler() {
      this.dialog.visible = false;
      this.$message({ message: this.$t('deposit.default.failed'), type: 'error' });
      // re-calling anti-reuse token
      this.fetchToken()
        .then(resp => {
          this.$refs.child.loading = false;
        })
        .catch(resp => {
          this.$refs.child.loading = false;
          this.$message({ message: this.$t('resetPassword.failed'), type: 'error' });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/default.scss';
@import '@/assets/css/components/deposit/multiButton.scss';
</style>
